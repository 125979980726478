import React from 'react';
import './Questionnaire.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SectionText from '../../components/SectionText/SectionText';
import SectionHeader from '../../components/SectionTitle/SectionHeader';
import BtnPrimary from '../../components/Buttons/BtnPrimary';

import { useTranslation } from 'react-i18next'

function Questionnaire() {
	const { t } = useTranslation();
	return (
		<section id="questionnaire" className="section section--blue" data-nav="Twórcy">
			<article className="article">
				<SectionHeader class="section__header" text={t("QUESTIONNAIRE_HEADER")} />
				<SectionTitle class="section__title" text={t("QUESTIONNAIRE_TITLE")} />
				<SectionText class="description__text" text={t("QUESTIONNAIRE_TEXT")} />
				<SectionText class="description__text" text={t("QUESTIONNAIRE_TEXT_TWO")} />
				<SectionText class="description__text" text={t("QUESTIONNAIRE_TEXT_THREE")} />
			</article>
			<article className="article article--btn">
				<BtnPrimary classA="App-link" target="_blank" class="btn__primary" text={t("QUESTIONNAIRE_BUTTON")} href="https://forms.gle/pyDHgQSFjLPRcTpB9" />
			</article>
		</section>
	)
}

export default Questionnaire
import React from 'react';
import './Header.scss';
import PageTitle from '../PageTitle/PageTitle';
// import { PAGE_2ND_TITLE } from "../../constants";
// import Navbar from './Navbar';
import Navbar2nd from './Navbar2nd'
import { useTranslation } from 'react-i18next';


function Header2nd() {
  const { t } = useTranslation();
  
  return (
    <header className="App-header-2nd">
      <Navbar2nd/>
      <article className="article">
        <PageTitle name="page__title" text={t("page_2nd_title") }/>
      </article>
      <article className="article">
      </article>
    </header>
  );
}

export default Header2nd;
import React from 'react';
import ToolName from './ToolName';


function ToolBox(props) {

    return (
        <div className="box">
            <div className="icon__box">
                <div className="box__icon"></div>
            </div>
            <ToolName name={props.name} />
            <p className="description__text"></p>
        </div>
    )
}

export default ToolBox
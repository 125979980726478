import React from 'react';
import './SectionText.scss';

function SectionText(props) {

  const addElem = (elem) => {

    if(elem.text !== undefined) {
      return (elem.text).map((item, index) => {
        //console.log(item)
        if(typeof item === 'string') {return item}
        if(item.bold !== undefined) {return <strong key={index + item.toString()} className="text--strong">{item.bold}</strong>}
      })
    }
  }

  return (
    <p className={props.class}>
      {typeof props.text === 'string' ? props.text : addElem(props.text)}
    </p>
  );
}

export default SectionText;
import React from 'react';
import './PointPattern.scss';

function PointPattern(props) {

  return (
    <div className="pattern__box">
      <img src={props.color} className={"points__pattern points__pattern--" + props.position + " points__pattern--" + props.orientation} alt="pattern" />
    </div>
  )
}

export default PointPattern;
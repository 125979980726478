import React from 'react';


function FooterItem(props) {
    return (
        <li className="list__elem">
            <a
                title={props.data.title}
                href={props.data.linkUrl}
                target={props.data.target}
                rel={props.data.rel}
            >
                {props.data.text}
            </a>
        </li>


    )
}

export default FooterItem
import React from 'react';
import "./ContactListTitle.scss";


function ContactListTitle(props) {
    return (
        <li className={props.name}>
            {props.text}
        </li>
    );
}

export default ContactListTitle;
import React from 'react';

function ToolName(props) {
    return (

        <h4 className="box__title">
            {props.name}
        </h4>
    );
}



export default ToolName;
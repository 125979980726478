import React, { useState, useEffect } from 'react';
import './Stats.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SectionText from '../../components/SectionText/SectionText';
import Chart from './Chart';
import blue from '../../images/icons/Pattern-blue.svg';
import PointPattern from '../../components/Pattern/PointPattern';

import { useTranslation } from 'react-i18next'

function Stats() {
  const { t } = useTranslation();
  const [value1, setValue1] = useState(true);
  const [value2, setValue2] = useState(true);
  const [value3, setValue3] = useState(true);

  function moveStats() {
    setValue1(42);
    setValue2(78);
    setValue3(14);
  }

  function removeStats() {
    setValue1(0);
    setValue2(0);
    setValue3(0);
  }

  useEffect(() => {
    function handleScroll() {
      let stats = document.querySelector('#stats');
      let distanceStats = stats.offsetTop;
      window.pageYOffset >= distanceStats ? moveStats() : removeStats();
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section id="stats" className="section">
      <article className="article">
        <PointPattern color={blue} position="center" orientation="horizontal" />
        <SectionTitle class="section__title" text={t("STATS_TITLE")} />
      </article>
      <article className="article stats-text__box">
        <SectionText class="section__text" text={t("STATS_TEXT1")} />
        <div className="border-line__box">
          <div className="border-line"></div>
        </div>
        <SectionText class="section__text" text={t("STATS_TEXT2")} />
      </article>
      <article className="article stats-charts__box">
        <Chart number={value1} text={t("text1")} />
        <Chart number={value2} text={t("text2")} />
        <Chart number={value3} text={t("text3")} />
      </article>
    </section>
  );
}

export default Stats;

import React from 'react';
import './BtnPrimary.scss';

function BtnPrimary(props) {
  return (
    <a className={props.classA} target={props.target} href={props.href}>
      <button className={props.class}>
        {props.text}
      </button>
    </a>
  );
}

export default BtnPrimary;
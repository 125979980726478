import React from 'react';
import './SectionTitle.scss';

function ArticleTitle(props) {
  return (
    <h4 className={props.class}>
      {props.text}
    </h4>
  );
}

export default ArticleTitle;
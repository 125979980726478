import React, { useState, useEffect } from 'react';
import './BtnTop.scss';

function BtnTop() {
  const [visible, setVisible] = useState(true);

  const go = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    function handleScroll() {
      let viewportHeight = window.innerHeight + 100;
      window.pageYOffset < viewportHeight ? setVisible(visible) : setVisible(!visible);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={"btn-go-top__box " + (visible ? "btn-go-top__off" : "btn-go-top__on")}>
      <button onClick={go} className="btn-go-top">
      </button>
    </div>
  );
}

export default BtnTop;
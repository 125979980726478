import React from 'react';
import './Header.scss';
import circle from '../../images/icons/Ellipse 1.svg';
import logo from '../../images/icons/LOGO.svg';
import phone1 from '../../images/photos/iPhone X.png';
import phone2 from '../../images/photos/iPhone X2.png';
import PageTitle from '../PageTitle/PageTitle';
import BtnPrimary from '../Buttons/BtnPrimary';
import BtnDown from '../Buttons/BtnDown';
import PointPattern from '../Pattern/PointPattern';
import grey from '../../images/icons/Pattern-grey.svg';
import white from '../../images/icons/Pattern-white.svg';
import Navbar from './Navbar';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();

  return (
    <header className="App-header">
      <Navbar />
      <img src={circle} className="App-circle" alt="circle" />
      <article className="article">
        <img src={logo} className="App-logo" alt="logo" />
        <PageTitle name="page__title" text={t('page_title')} />
        <PageTitle name="page__subtitle" text={t('subtitle')} />
        <BtnPrimary classA="App-link" class="btn__primary" text={t("call_to_action")} href="#questionnaire" />
      </article>
      <article className="article">
        <img src={phone2} className="App-picture" alt="mobile phone" />
        <img src={phone1} className="App-picture" alt="mobile phone" />
      </article>
      <PointPattern color={white} position="center" orientation="horizontal" />
      <PointPattern color={grey} position="left" orientation="vertical" />
      {/* <div className="pattern__box">
        <img src={patternGrey} className="points__pattern--grey" alt="logo" />
        <img src={patternWhite} className="points__pattern--white" alt="logo" />
      </div> */}
      <BtnDown href="#dialog"/>
    </header>
  );
}

export default Header;
import React from 'react';
import './Consumers.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SectionHeader from '../../components/SectionTitle/SectionHeader';
import SectionText from '../../components/SectionText/SectionText';
import UserBox from './UserBox';
import PointPattern from '../../components/Pattern/PointPattern';
import EllipsePattern from '../../components/Pattern/EllipsePattern';

import children from '../../images/icons/dzieci_1.svg';
import parents from '../../images/icons/rodzice_4.svg';
import teachers from '../../images/icons/nauczyciele_2.svg';
import terapist from '../../images/icons/psycholodzy_1.svg';
import white from '../../images/icons/Pattern-white.svg';
import greyE from '../../images/icons/Ellipse-grey.svg';

import { useTranslation } from 'react-i18next'


function Consumers() {
  const { t, i18n } = useTranslation();
  return (
    <section id="consumers" className="section">
      <article className="article">
        <PointPattern color={white} position="center" orientation="horizontal" />
        <div className="text__box">
          <SectionHeader class="section__header" text={t("CONSUMERS_HEADER")} />
          <SectionTitle class="section__title" text={t("CONSUMERS_TITLE")} />
          <SectionText class="section__text" text={t("CONSUMERS_TEXT")} />
        </div>
        <EllipsePattern color={greyE} orientation="outher" />
        <EllipsePattern color={greyE} orientation="inner" />
      </article>
      <article className="article">
        <UserBox title={t("CONSUMERS_USER1")} texts={i18n.t("CONSUMERS_USER1_TEXT", { returnObjects: true })} img={children} />
        <UserBox title={t("CONSUMERS_USER2")} texts={i18n.t("CONSUMERS_USER2_TEXT", { returnObjects: true })} img={parents} />
        <UserBox title={t("CONSUMERS_USER3")} texts={i18n.t("CONSUMERS_USER3_TEXT", { returnObjects: true })} img={teachers} />
        <UserBox title={t("CONSUMERS_USER4")} texts={i18n.t("CONSUMERS_USER4_TEXT", { returnObjects: true })} img={terapist} />
      </article>
    </section>
  );
}

export default Consumers;
import React from 'react';
import './Prototype.scss';
import photo1 from '../../images/photos/sytem-zetonowy-level3-A.jpg';
import photo2 from '../../images/photos/sytem-zetonowy-level3-B.jpg';
import photo3 from '../../images/photos/system-zetonowy-papierowy.jpg';
import photo4 from '../../images/photos/system-zetonowy-level9.jpg';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import ArticleTitle from '../../components/SectionTitle/ArticleTitle';
import SectionHeader from '../../components/SectionTitle/SectionHeader';
import SectionText from '../../components/SectionText/SectionText';
import blue from '../../images/icons/Pattern-blue.svg';
import PointPattern from '../../components/Pattern/PointPattern';
import EllipsePattern from '../../components/Pattern/EllipsePattern';
import greyE from '../../images/icons/Ellipse-grey.svg';

import { useTranslation } from 'react-i18next'


function Prototype() {
  const { t } = useTranslation();
  return (
    <section id="prototype" className="section">
      <article className="article">
        <PointPattern color={blue} position="center" orientation="horizontal" />
        <SectionHeader class="section__header" text={t("PROTOTYPE_HEADER")} />
        <SectionTitle class="section__title" text={t("PROTOTYPE_TITLE")} />
      </article>
      <article className="article">
        <div className="flex-row">
          <div className="text__box">
            <ArticleTitle class="article__title" text={t("PROTOTYPE_SUBTITLE")} />
            <SectionText class="section__text" text={t("PROTOTYPE_TEXT1")} />
          </div>
          <div className="img__box">
            <img src={photo1} className="section__photo" alt="dashboard with points" />
          </div>
        </div>
        <div className="flex-row">
          <div className="text__box">
            <SectionText class="section__text" text={t("PROTOTYPE_TEXT2")} />
          </div>
          <div className="img__box">
            <img src={photo2} className="section__photo" alt="dashboard with points" />
          </div>
        </div>
        <div className="flex-row">
          <div className="text__box">
            <SectionText class="section__text" text={t("PROTOTYPE_TEXT3")} />
          </div>
          <div className="img__box">
            <img src={photo3} className="section__photo" alt="dashboard with points" />
          </div>
        </div>
        <div className="flex-row">
          <div className="text__box">
            <SectionText class="section__text" text={t("PROTOTYPE_TEXT4")} />
          </div>
          <div className="img__box">
            <img src={photo4} className="section__photo" alt="dashboard with points" />
          </div>
        </div>
      </article>
      <EllipsePattern color={greyE} orientation="outher" />
      <EllipsePattern color={greyE} orientation="inner" />
    </section>
  )
}

export default Prototype;
import React from 'react';
import './Dialog.scss';
import terapist from '../../images/icons/psycholodzy_1.svg';
import white from '../../images/icons/Ellipse 7.svg';
import ArticleTitle from '../../components/SectionTitle/ArticleTitle';
import EllipsePattern from '../../components/Pattern/EllipsePattern';

import Question from '../Dialog/Question';
import Question2 from '../Dialog/Question2';
import { useTranslation } from 'react-i18next';

function Dialog() {
  const { t, i18n } = useTranslation();

  const dialogText1 = i18n.t('dialog_text', { returnObjects: true }).map(item => <Question data={item} key={item.id} />)
  const dialogText2 = i18n.t('DIALOG_TEXT_TWO', { returnObjects: true }).map(item => <Question2 data={item} key={item.id} />)
  return (
    <section id="dialog" className="section">
      <div className="dialog__row">
        <article className="article article__icon">
          <img src={terapist} className="icon--large" alt="icon" />
        </article>
        <article className="article article__questions">
          <ArticleTitle class="section__title" text={t("dialog_title")} />
          <ul className="question__list">
            {dialogText1}
          </ul>
        </article>
      </div>

      <div className="dialog__row">
        <EllipsePattern color={white} orientation="outher" />
        <EllipsePattern color={white} orientation="inner" />
        <article className="article article__questions">
          <ArticleTitle class="section__title" text={t("DIALOG_SUBTITLE")} />
          <ul className="question__list">
            {dialogText2}
          </ul>
        </article>
      </div>
    </section>
  );
}

export default Dialog;
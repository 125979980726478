import React from 'react';
import './PhotoGrid.scss';
import GridItem from '../PhotoItem/GridItem';

function PhotoGrid({ collection }) {

  return (
    <article className="photo__gallery">
      {collection.map((item, index) => 
        <GridItem key={"grid" + index} name="grid__item" src={item} />
      )}
    </article>
  )
}

export default PhotoGrid;
import React from 'react';
import './Main.scss';
import Creators from '../../pages/Creators/Creators';
import Team from '../../pages/Team/Team';
import Works from '../../pages/Works/Works';
import Workshops from '../../pages/Workshops/Workshops';
import Tools from '../../pages/Tools/Tools';

function Main2nd() {
  return (
    <main className="App-main">
      <Creators />
      <Team />
      <Works />
      <Workshops />
      <Tools />
    </main>
  );
}

export default Main2nd;
import React from 'react';
import SectionText from '../../components/SectionText/SectionText';
import line from '../../images/icons/Line26.svg';
import './HowRows.scss';


function HowRows(props) {
  
  return (
    <article className="article--row">
      <article className="article--text">
        <img src={line} className="section__line" alt="line" />
        <SectionText class="section__text" text={props.text} />
      </article>
      <div className="img__box">
        <img src={process.env.PUBLIC_URL + props.src} className="section__icon" alt="icon" />
      </div>
    </article>
  )
}

export default HowRows
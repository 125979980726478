import React from 'react';
import PhotoItem from '../PhotoItem/PhotoItem';
import './PhotoGridCreators.scss';

function PhotoGridCreators() {
    let photoItems2 = []
    for (let i = 0; i < 5; i++) {
        photoItems2.push(<PhotoItem class="gallery__item" key={i} />)
    };

    return (
        <article className="article gallery--img">
            {photoItems2}
        </article>
    )
}

export default PhotoGridCreators
import React from 'react';
import PhotoItem from "../PhotoItem/PhotoItem";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import './PhotoSwiper.scss';
SwiperCore.use([Navigation, Pagination]);

function PhotoSwiper({ collection }) {

  return (
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {collection.map((item, index) => <SwiperSlide style={{width: "100%"}} key={item.toString() + index} >
          <PhotoItem name="gallery__item" src={item}/>
        </SwiperSlide>)}
      </Swiper>
  )
}

export default PhotoSwiper
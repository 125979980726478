import React from 'react';
import './SectionTitle.scss';

function SectionTitle(props) {
  return (
    <h2 className={props.class}>
      {props.text}
    </h2>
  );
}

export default SectionTitle;
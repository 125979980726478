import React from 'react';
import "./PhotoItem.scss"

function GridItem({ name, src }) {
  return (
    <figure className={name}>
      <img alt="" className="grid__photo" src={process.env.PUBLIC_URL + `/images/photos/${src}`}></img>
    </figure>
  )
}

export default GridItem
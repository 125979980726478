import React from 'react';
import './Workshops.scss';
import PhotoGrid from '../../components/PhotoGrid/PhotoGrid';
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { WORKSHOPS_PHOTOS } from '../../constants';
import blue from "../../images/icons/Pattern-blue.svg";
import PointPattern from '../../components/Pattern/PointPattern';

import { useTranslation } from 'react-i18next'

function Workshops() {
	const { t } = useTranslation();
	return (
		<section id="workshops" class="section workshops">
			<article className="article article--text">
				<PointPattern color={blue} position="left" orientation="vertical" />
				<SectionTitle class="section__title" text={t("WORKSHOPS_TITLE")} />
			</article>
			<PhotoGrid collection={WORKSHOPS_PHOTOS} />
		</section>
	)
}

export default Workshops
import React from 'react';
import './Mission.scss';
import photo from '../../images/photos/47whM1ZEvfU.png';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import ArticleTitle from '../../components/SectionTitle/ArticleTitle';
import SectionHeader from '../../components/SectionTitle/SectionHeader';
import SectionText from '../../components/SectionText/SectionText';
import PointPattern from '../../components/Pattern/PointPattern';
import EllipsePattern from '../../components/Pattern/EllipsePattern';
import white from '../../images/icons/Pattern-white.svg';
import greyE from '../../images/icons/Ellipse-grey.svg';

import { useTranslation } from 'react-i18next';

function Mission() {
  const { t, i18n } = useTranslation();
  return (
    <section id="mission" className="section">
      <article className="article">
        <SectionHeader class="section__header" text={t("mission_header")} />
      </article>
      <article className="article">
        <div className="text__box">
          <SectionTitle class="section__title" text={t("mission_title")} />
          {i18n.t('mission_text', { returnObjects: true }).map((item, index) => <SectionText key={index + item.toString()} class="section__text" text={item} />)}
          <ArticleTitle class="article__title" text={t("mission_subtitle")} />
          <ul className="box__list">
            {i18n.t('mission_list', {returnObjects: true}).map((item, index) => <li key={index + item.toString()} className="section__text">{item}</li>)}
          </ul>
        </div>
        <PointPattern color={white} position="right" orientation="vertical" />
        <div className="img__box">
          <img src={photo} className="section__photo" alt="team at work" />
        </div>
      </article>
      <EllipsePattern color={greyE} orientation="outher" />
      <EllipsePattern color={greyE} orientation="inner" />
    </section>
  );
}

export default Mission;
import React from 'react'
import Flags from "country-flag-icons/react/3x2";

function FlagBtns({handleClick, isLang}){
  return (
    <div className="lang-check">
    <button
      className={`lang-btn lang${isLang === "pl" ? " pl" : ""}`}
      onClick={() => handleClick("pl")}
    >
      <Flags.PL className="flags" />
    </button>
    <button
      className={`lang-btn lang${isLang === "en" ? " en" : ""}`}
      onClick={() => handleClick("en")}
    >
      <Flags.GB className="flags" />
    </button>
  </div>
  )
}

export default FlagBtns
import React from 'react';
import './PointPattern.scss';

function EllipsePattern(props) {

  return (
    <div className={"ellipse__box ellipse__box--" + props.orientation}>
      <img src={props.color} className={"ellipse__pattern"} alt="ellipse" />
    </div>
  )
}

export default EllipsePattern;
import React from 'react';
import './SectionTitle.scss';

function SectionHeader(props) {
  return (
    <h3 className={props.class}>
      {props.text}
    </h3>
  );
}

export default SectionHeader;
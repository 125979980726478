
export const CREATORS_PHOTOS = ["_64A2325.jpg", "_64A2418.jpg", "_64A2597.jpg", "_64A2710.jpg", "_64A2726.jpg"]



/* section "team" */
export const TEAM_HEADER = "TEAM"
export const TEAM_TITLE = "Ludzie za kurtyną"

export const WORKSHOPS_PHOTOS = ["_64A2325.jpg", "_64A2418.jpg", "_64A2597.jpg", "_64A2710.jpg", "_64A2726.jpg", "_64A2726.jpg", "_64A2726.jpg"]

export const TOOLS_NAMES = [
  { "id": 1, "name": "Python" },
  { "id": 2, "name": "Javascript" },
  { "id": 3, "name": "HTML5" },
  { "id": 4, "name": "CSS/SASS" },
  { "id": 5, "name": "Django" },
  { "id": 6, "name": "React" },
  { "id": 7, "name": "PostgreSQL" },
  { "id": 8, "name": "Jira" },
  { "id": 9, "name": "Git" },
  { "id": 10, "name": "Heroku" },
  { "id": 11, "name": "CircleCI" },
  { "id": 12, "name": "Github" }
]



export const CONTACT_INFO_ONE = [

  {
    title: "Napisz E-mail do Leszka Miotk",
    linkUrl: "mailto:l.miotk.py@gmail.com",
    text: "l.miotk.py@gmail.com"
  },
  {
    title: "Zadzwoń do Leszka Miotk",
    linkUrl: "tel:+48507585882",
    text: "+48507585882"
  },

]

export const CONTACT_INFO_TWO = [
  {
    title: "Szkoła programowania",
    linkUrl: "http://www.codeme.pl",
    target: "_blank",
    text: "Fundacja Code:me",
    rel: "noopener noreferrer"
  },
  {
    title: "Hub Technologiczny",
    linkUrl: "https://www.hs3.pl",
    target: "_blank",
    text: "Hacker:Space Trójmiasto",
    rel: "noopener noreferrer"
  },
  {
    title: "Gabinet psychoterapii",
    linkUrl: "https://zielony-koliber-gabinet-psychologiczny.business.site/",
    target: "_blank",
    text: "Zielony Koliber",
    rel: "noopener noreferrer"
  },
  {
    title: "E-technologie dla dzieci z autyzmem",
    linkUrl: "http://autyzm.eti.pg.gda.pl/",
    target: "_blank",
    text: "Autyzm-PG",
    rel: "noopener noreferrer"
  },
  {
    title: "Gabinet Zuzanny Wieteckiej",
    linkUrl: "https://www.terapiadladziecka.pl",
    target: "_blank",
    text: "Terapia Dla Dziecka",
    rel: "noopener noreferrer"
  },
  {
    title: "Diagnoza i terapia dzieci",
    linkUrl: "https://anna-przewocka-indywidualna-praktyka.business.site/",
    target: "_blank",
    text: "Anna Przewłócka",
    rel: "noopener noreferrer"
  },
  {
    title: "Producent oprogramowania",
    linkUrl: "https://www.jetbrains.com/",
    target: "_blank",
    text: "JetBrains",
    rel: "noopener noreferrer"
  }

]

export const CONTACT_INFO_THREE = [
  {
    title: "Niebieski Skarb - Stowarzyszenie",
    linkUrl: "http://niebieskiskarb.pl/",
    target: "_blank",
    text: "Stowarzyszenie Niebieski Skarb",
    rel: "noopener noreferrer"
  },
  {
    title: "Fundacja JiM | Stworzymy lepszy świat",
    linkUrl: "https://jim.org/",
    target: "_blank",
    text: "Fundacja JIM",
    rel: "noopener noreferrer"
  },
  {
    title: "Free ABA Video Lessons",
    linkUrl: "https://firstpathautism.com/",
    target: "_blank",
    text: "First Path Autism",
    rel: "noopener noreferrer"
  },
]

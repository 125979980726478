import React from 'react';
import './HowSection.scss';
import ArticleTitle from '../../components/SectionTitle/ArticleTitle';
import grey from '../../images/icons/Pattern-grey.svg';
import PointPattern from '../../components/Pattern/PointPattern';
import EllipsePattern from '../../components/Pattern/EllipsePattern';
import greyE from '../../images/icons/Ellipse-grey.svg';
import SectionSwiper from '../../components/SectionSwiper/SectionSwiper';
import { useTranslation } from 'react-i18next';

function HowSection() {
	const { t, i18n } = useTranslation();
	return (
		<section id="how-section" className="section how-section">
			<article className="how__title">
				<PointPattern color={grey} position="right" orientation="horizontal" />
				<ArticleTitle class="article__title" text={t("HOW_TITLE")} />
			</article>
			<article className="container--rows">
				<SectionSwiper collection={i18n.t("HOW_ROW", { returnObjects: true })} />
			</article>
			<EllipsePattern color={greyE} orientation="outher" />
			<EllipsePattern color={greyE} orientation="inner" />
		</section>
	)
}

export default HowSection
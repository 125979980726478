import React from "react";
import "./Footer.scss";
import { CONTACT_INFO_ONE, CONTACT_INFO_TWO, CONTACT_INFO_THREE } from "../../constants.js";
import SectionTitle from "../SectionTitle/SectionTitle";
import blue from "../../images/icons/Pattern-blue.svg";
import FooterAuthors from "./FooterAuthors";
import FooterItem from "./FooterItem";
import ContactListTitle from '../ContactListTitle/ContactListTitle';
import PointPattern from '../Pattern/PointPattern';
import { useTranslation } from 'react-i18next'

function Footer() {

  const { t} = useTranslation();
  const contactOneComponents = CONTACT_INFO_ONE.map((info, index) => <FooterItem key={index + info.toString()} data={info} />)
  const contactTwoComponents = CONTACT_INFO_TWO.map((info, index) => <FooterItem key={index + info.toString()} data={info} />)
  const contactThreeComponents = CONTACT_INFO_THREE.map((info, index) => <FooterItem key={index + info.toString()} data={info} />)
  return (
    <footer className="App-footer">
      <section className="footer__title" id="footer">
        <SectionTitle class="section__title" text={t("footer_title")} />
        <PointPattern color={blue} position="center" orientation="horizontal" />
      </section>
      <section className="footer__info">
        <article className="article article--text">
          <ul className="persons__list">
            <ContactListTitle name="list__elem" text={t("list_one_title")} />
            {contactOneComponents}
          </ul>
        </article>
        <article className="article article--text">
          <ul className="persons__list">
            <ContactListTitle name="list__elem" text={t("list_two_title")} />
            {contactTwoComponents}
          </ul>
        </article>
        <article className="article article--text">
          <ul className="persons__list">
            <ContactListTitle name="list__elem" text={t("list_three_title")} />
            {contactThreeComponents}
          </ul>
        </article>
      </section>
      <section className="footer__authors">
        <FooterAuthors />
      </section>
    </footer>
  );
}

export default Footer;

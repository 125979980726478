import React from 'react';
import './PointPattern.scss';

function PlusPattern(props) {

  return (
    <img src={props.color} className={"plus__pattern"} alt="plus" />
  )
}

export default PlusPattern;
import React from 'react';
import './Creators.scss';
import '../../components/PhotoGridCreators/PhotoGridCreators'
import { CREATORS_PHOTOS } from '../../constants';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SectionText from '../../components/SectionText/SectionText';
import PhotoSwiper from '../../components/PhotoSwiper/PhotoSwiper';
import SectionHeader from '../../components/SectionTitle/SectionHeader';
import grey from '../../images/icons/Pattern-grey.svg';
import PointPattern from '../../components/Pattern/PointPattern';
import { useTranslation } from 'react-i18next';

function Creators() {
	const { t } = useTranslation();
	return (
		<section id="creators" className="section section--blue" data-nav="Twórcy">
			<article className="article article--text">
				<PointPattern color={grey} position="center" orientation="horizontal" />
				<SectionHeader class="section__header" text={t("CREATORS_HEADER")} />
				<SectionTitle class="section__title" text={t("CREATORS_TITLE")} />
				<SectionText class="description__text" text={t("CREATORS_TEXT")} />
			</article>
			<article className="article article--img">
				<PhotoSwiper collection={CREATORS_PHOTOS} />
			</article>
		</section>
	)
}

export default Creators
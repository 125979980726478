import React from 'react';
import './Main.scss';
import Dialog from '../../pages/Dialog/Dialog';
import Stats from '../../pages/Stats/Stats';
import Consumers from '../../pages/Consumers/Consumers';
import Mission from '../../pages/Mission/Mission';
import Prototype from '../../pages/Prototype/Prototype';
import HowSection from '../../pages/HowSection/HowSection';
import Questionnaire from '../../pages/Questionnaire/Questionnaire';

function Main() {
  return (
    <main className="App-main">
      <Dialog />
      <Stats />
      <Consumers />
      <Mission />
      <Prototype />
      <HowSection />
      <Questionnaire />
    </main>
  );
}

export default Main;
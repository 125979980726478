import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import './Navbar.scss';
import logo from '../../images/icons/LOGO.svg';
import hamburger from '../../images/icons/hamburger.png';
import { useTranslation } from 'react-i18next';
import FlagBtns from './FlagBtns';

function Navbar2nd() {
  const [open, setOpen] = useState(false);
  //const navSwitch = setOpen(open => !open);
  const [isLang, setLang] = useState("pl");
  const { t, i18n } = useTranslation();

  function handleClick(lang) {
    setLang(lang);
    i18n.changeLanguage(lang);
  }

  return (
    <nav className="navbar">
      <img src={logo} className="brand-logo" id="no-opacity" alt="logo" />
      <div className={"nav-links " + (open && "nav-links--on")}>
        <NavLink className="link" activeClassName="link--active" exact to="/">{t("strona_główna")}</NavLink>
        <NavLink className="link" activeClassName="link--active" exact to="/realizacja">{t("realizacja")}</NavLink>
        <a className="link" href="#footer">{t("kontakt")}</a>
      </div>
   

      <div className="right-bar">
        <FlagBtns handleClick={handleClick} isLang={isLang} />
        <button className="hamburger-btn">
          <img onClick={() => setOpen(!open)} src={hamburger} className="hamburger-icon" alt="logo" />
        </button>
      </div>
    </nav>
  )
}

export default Navbar2nd;
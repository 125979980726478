import React from 'react';
import './Team.scss';
import SectionHeader from '../../components/SectionTitle/SectionHeader';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import MemberPhoto from '../Team/MemberPhoto';

import { useTranslation } from 'react-i18next'

function Team() {
  const { t, i18n } = useTranslation();
  const teamPhotos = i18n.t("TEAM_MEMBERS", { returnObjects: true }).map(item => <MemberPhoto data={item} key={item.id} />)
  return (
    <section id="team" className="section">
      <SectionHeader class="section__header" text={t("TEAM_HEADER")} />

      <SectionTitle class="section__title" text={t("TEAM_TITLE")} />

      <article className="article article--boxes">
        {teamPhotos}
      </article>

    </section>
  )
}

export default Team
import React, { useState, useEffect } from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';

function Chart(props) {

  const [val, setVal] = useState(0);

  useEffect(() => {
    const interval = setTimeout(() => {
      val < props.number && setVal(val => val + 1);
    }, 25);
    return () => clearTimeout(interval);
  },);

  return (

    <div className="stat__box">
      <div className="stat__chart">
        <CircularSlider
          width="180"
          label="savings"
          labelColor="#ffffff"
          knobColor="#005a58"
          hideKnob="true"
          progressColorFrom="#0041C4"
          progressColorTo="#0041C4"
          progressSize={25}
          progressLineCap="flat"
          trackColor="#eeeeee"
          trackSize={25}
          min={0}
          max={100}
          dataIndex={val}
        />
        <h5 className="number">
          {val}<span className="procentage">%</span>
        </h5>
      </div>
      <div className="stat__description">
        <p className="info__text">
          {props.text}
        </p>
      </div>
    </div>
  );
}

export default Chart;
import React from 'react';
import './Tools.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SectionText from '../../components/SectionText/SectionText';
import green from "../../images/icons/Pattern-green.svg";
import ToolBox from './ToolBox';
import { TOOLS_NAMES } from '../../constants';
import PointPattern from '../../components/Pattern/PointPattern';

import { useTranslation } from 'react-i18next'


function Tools() {
	const { t } = useTranslation();
	const tools = TOOLS_NAMES.map(tool => <ToolBox data={TOOLS_NAMES} key={tool.id} name={tool.name} />)
	return (
		<section id="tools" className="section section--dark">
			<article className="article article--text">
				<PointPattern color={green} position="center" orientation="horizontal" />
				<SectionTitle class="section__title" text={t("TOOLS_TITLE")} />
				<SectionText class="description__text" text={t("TOOLS_TEXT")} />
			</article>
			<article className="article article--boxes">
				{tools}
			</article>
		</section>
	)
}

export default Tools


import React from 'react';
import './Works.scss';

import SectionText from '../../components/SectionText/SectionText';
import SectionHeader from '../../components/SectionTitle/SectionHeader';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import blue from "../../images/icons/Pattern-blue.svg";
import patternDot from "../../images/icons/Ellipse 62.svg";
import photo1 from "../../images/photos/64A2602.jpg";
import photo2 from "../../images/photos/_64A2418.jpg";
import photo3 from "../../images/photos/_64A2726.jpg";
import photo4 from "../../images/photos/_64A3168.jpg";
import PointPattern from '../../components/Pattern/PointPattern';

import { useTranslation } from 'react-i18next'


function Works() {
	const { t } = useTranslation();
	return (
		<section id="works" className="section section--dark" data-nav="Realizacja">
			<SectionHeader class="section__header" text={t("WORKS_HEADER")} />
			<article className="article article--text">
				<section className="works__title">
					<SectionTitle class="section__title" text={t("WORKS_TITLE")} />
					<PointPattern color={blue} position="right" orientation="vertical" />
				</section>
				<div className="row">
					<div className="box">
						<img src={patternDot} className="dot__pattern" alt="pattern" />
					</div>
					<div className="text">
						<SectionText class="description__text" text={t("WORKS_TEXT1")} />
					</div>
					<div className="img__box">
						<img src={photo1} className="section__icon" alt="icon" />
					</div>
				</div>
				<div className="row">
					<div className="box">
						<img src={patternDot} className="dot__pattern" alt="pattern" />
						<img src={patternDot} className="dot__pattern" alt="pattern" />
					</div>
					<div className="text">
						<SectionText class="description__text" text={t("WORKS_TEXT2")} />
					</div>
					<div className="img__box">
						<img src={photo2} className="section__icon" alt="icon" />
					</div>
				</div>
				<div className="row">
					<div className="box">
						<img src={patternDot} className="dot__pattern" alt="pattern" />
						<img src={patternDot} className="dot__pattern" alt="pattern" />
						<img src={patternDot} className="dot__pattern" alt="pattern" />
					</div>
					<div className="text">
						<SectionText class="description__text" text={t("WORKS_TEXT3")} />
					</div>
					<div className="img__box">
						<img src={photo3} className="section__icon" alt="icon" />
					</div>
				</div>
				<div className="row">
					<div className="box">
						<img src={patternDot} className="dot__pattern" alt="pattern" />
						<img src={patternDot} className="dot__pattern" alt="pattern" />
						<img src={patternDot} className="dot__pattern" alt="pattern" />
						<img src={patternDot} className="dot__pattern" alt="pattern" />
					</div>
					<div className="text">
						<SectionText class="description__text" text={t("WORKS_TEXT4")} />
					</div>
					<div className="img__box">
						<img src={photo4} className="section__icon" alt="icon" />
					</div>
				</div>
			</article>

		</section>

	)
}

export default Works
import React from 'react';
import './PageTitle.scss';

function PageTitle(props) {
  return (
    <h1 className={props.name}>
      {props.text}
    </h1>
  );
}

export default PageTitle;
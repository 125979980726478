import React from 'react';
import HowRows from '../../pages/HowSection/HowRows';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import './SectionSwiper.scss';
SwiperCore.use([Navigation, Pagination]);

function SectionSwiper({ collection }) {

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {collection.map((item, index) => <SwiperSlide style={{ width: "100%" }} key={item.toString() + index} >
        <HowRows data={collection} key={item.id} text={item.text} src={item.src} />
      </SwiperSlide>)}
    </Swiper>
  )
}

export default SectionSwiper
import React from 'react';
import './Consumers.scss';
import ArticleTitle from '../../components/SectionTitle/ArticleTitle';
import PlusPattern from '../../components/Pattern/PlusPattern';
import blue from '../../images/icons/Plus-blue.svg';


function UserBox(props) {

  return (
    <div className="user__box">
      <ArticleTitle class="article__title" text={props.title} />
      <div className="circle">
        <img src={props.img} className="icon--large" alt="icon" />
      </div>
      <ul className="box__list">
        {(props.texts).map((item, index) => <li key={index + item.toString()} className="info__text">{item}</li>)}
      </ul>
      <PlusPattern color={blue} />
    </div>
  );
}

export default UserBox;
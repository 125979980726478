import React from 'react';
import './BtnDown.scss';

function BtnDown({href, text}) {
  return (
    <a className="buttonDown__link" href={href}>
      <button className="buttonDown">
      </button>
    </a>
  );
}

export default BtnDown;
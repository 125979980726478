import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.scss';
import './components/Header/Navbar.scss';
import Header from './components/Header/Header';
import Header2nd from './components/Header/Header2nd';
import Main from './components/Main/Main';
import Main2nd from './components/Main/Main2nd';
import Footer from './components/Footer/Footer';
import BtnTop from './components/Buttons/BtnTop';

function App() {

  return (
    <Router >
      <div className="App">
        <Switch>
          <Route path="/realizacja">
            <Header2nd />
            <Main2nd />
            <Footer />
            <BtnTop />
          </Route>

          <Route path="/">
            <Header />
            <Main />
            <Footer />
            <BtnTop />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;

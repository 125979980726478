import React from "react";


function Question(props) {
 
  return (
    <div className="list__row">
      {/* <img src={patternDot} className="dot__pattern" alt="pattern" /> */}
      <li className="list__item section__text">{props.data.question}</li>
    </div>
  );
}

export default Question;

import React from 'react';

function MemberPhoto({ data }) {
  return (
    <div className="box">
      <img src={process.env.PUBLIC_URL + data.src} className="box__img" alt=" " />
      <div className="text-box">
        <h4 className="box__title">{data.name}</h4>
        <h4 className="box__subtitle">{data.surname}</h4>
        <p className="description__text">{data.role}</p>
      </div>
    </div>
  )
}

export default MemberPhoto
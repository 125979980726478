import React from "react";

function Question2(props) {
  return (
    <>
      <div className="list__dots">
        {/* <img src={patternDot} className="dot__pattern" alt="pattern" />
                <img src={patternDot} className="dot__pattern" alt="pattern" />
                <img src={patternDot} className="dot__pattern" alt="pattern" /> */}
      </div>
      <li className="list__item section__text">{props.data.question}</li>
    </>
  );
}

export default Question2;

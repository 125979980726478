import React from 'react';
import logo from '../../images/icons/LOGO.svg';

function FooterAuthors() {
    return (
        <>
            <div className="row">
                <img src={logo} className="App-logo" alt="logo" />
            </div>
            <div className="row">
                <abbr title="designed by: Paweł Nieczuja-Ostrowski (UX-design) & Radosław Kuczyński (UI-design & graphics); developed by: Paweł Nieczuja-Ostrowski & Magdalena Rosłaniec"
                >Copyright &copy;{new Date().getFullYear()} Żeton</abbr>
            </div>
        </>
    )
}

export default FooterAuthors